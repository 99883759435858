<template>
  <div>
    <div class="row">
      <div class="col-6 mb-3">
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" title="Regresar a pantalla anterior" v-b-popover.hover.bottom.noninteractive @click="gotoDashboard" v-if="!es_proveedor && $ver(['Dashboard'])">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'arrow-left', }" class="icon btn-icon" />
          </a>
        </transition>
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" @click="(esActivo && (!profData.BloqueadoSinValidacion || !((es_proveedor && !es_interno) && (riesgos > 0 || vencidos > 0 || pendientes > 0 || sincarga > 0))) ) ? gotoOrdenes() : undefined"
            v-if="($ver(['Ordenes']) && !es_proveedor && profData.EsRepse) || ($ver(['Ordenes']) && es_proveedor && profData.EsRepse)"
            :class="(!esActivo || (profData.BloqueadoSinValidacion && (es_proveedor && !es_interno) && (riesgos > 0 || vencidos > 0 || pendientes > 0 || sincarga > 0))) ? 'disabled' : ''">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'folder-open', }" class="icon btn-icon" /> {{ es_proveedor ? 'MIS ORDENES' : 'VER ORDENES' }}
          </a>
        </transition>
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" title="Ver|Cargar Facturas, Complementos y N.C."
            @click="(esActivo && (!profData.BloqueadoSinValidacion || !((es_proveedor && !es_interno) && (riesgos > 0 || vencidos > 0 || pendientes > 0 || sincarga > 0))) ) ? gotoFacturas() : undefined"
            v-if="$ver(['Facturas'])"
            :class="!esActivo || (profData.BloqueadoSinValidacion && (es_proveedor && !es_interno) && (riesgos > 0 || vencidos > 0 || pendientes > 0 || sincarga > 0)) ? 'disabled' : ''">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope-open-text', }" class="icon btn-icon" /> {{ es_proveedor ? 'MIS CFDIS' : 'VER CFDIS' }}
          </a>
        </transition>
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border" @click="esActivo ? openEditProfile() : undefined" v-if="$editar(['Perfil'])" :class="!esActivo ? 'disabled' : ''">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit', }" class="icon btn-icon" /> {{ es_proveedor ? 'EDITAR MIS DATOS' : 'EDITAR DATOS' }}
          </a>
        </transition>
      </div>
      <div class="col-6 mb-3 text-right">
        <transition name="slide-fade" appear v-if="!es_proveedor && $validar(['Perfil'])">
          <a class="btn btn-link btn-border mr-1" :title="profData.BloqueadoSinValidacion ? 'Activar carga de CFDIS y/o OC sin que el perfil este completo' : 'Bloquear carga de CFDIS y/o OC si el perfil no esta completo'"
            @click="esActivo && !es_proveedor && $validar(['Perfil']) ? bloquearPerfil() : undefined" :class="!esActivo ? 'disabled' : ''">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: profData.BloqueadoSinValidacion ? 'lock' : 'lock-open', }" class="icon btn-icon" />
            {{ profData.BloqueadoSinValidacion ? 'DESBLOQUEAR PERFIL' : 'BLOQUEAR PERFIL' }}
          </a>
        </transition>
        <transition name="slide-fade" appear v-if="!es_proveedor && $validar(['Perfil'])">
          <a class="btn btn-link btn-border mr-1" :title="profData.OmitirValidacionDocs ? 'Activar validacion de Docs.(CSF y 32D)' : 'Permitir carga de archivos sin validacion de Docs.(CSF y 32D)'"
            @click="esActivo && !es_proveedor && $validar(['Perfil']) ? bloquearValidacion() : undefined" :class="!esActivo ? 'disabled' : ''">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: profData.OmitirValidacionDocs ? 'file-circle-xmark' : 'file-circle-check', }" class="icon btn-icon" />
            {{ profData.OmitirValidacionDocs ? 'ACTIVAR VALIDACION' : 'OMITIR VALIDACION' }}
          </a>
        </transition>
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border" @click="openManual()">
            <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'info-circle', }" class="icon btn-icon" /> MANUAL
          </a>
        </transition>
      </div>
    </div>
    <div class="row" v-if="!esActivo">
      <div class="col-12">
        <b-alert show variant="danger">** Este Proveedor se encuentra INACTIVO, por lo que no puede actualizar sus datos. Para mayor información, por favor comuníquese al correo proveedores@giaguila.com.mx</b-alert>
      </div>
    </div>
    <div class="row" v-if="listaNegra.length && !es_proveedor">
      <div class="col-12">
        <b-alert show variant="danger">Este Proveedor se encuentra en la lista de EDOS. y  EFOS. del SAT con los siguientes datos:
          <span v-for="registro in listaNegra"
            :key="registro.Id">
            [Fecha: {{ registro.Fecha }} Situación: {{ registro.Situacion === 'DF' ? 'Definitivo' : (registro.Situacion === 'DV' ? 'Desvirtuado' : (registro.Situacion === 'P' ? 'Presunto' : (registro.Situacion === 'SF' ? 'Sentencia Favorable' : ''))) }}]
          </span>
        </b-alert>
      </div>
    </div>
    <div class="row" v-if="profData.TotalSinRep > 0 ">
      <div class="col-12">
        <b-alert show variant="warning">
          ⚠️ Este proveedor tiene facturas pagadas con R.E.P.s pendientes de entregar.
          <span class="text-ellipsis"
              title="⚠️ Facts. Pag con R.E.P. Pendientes"
              v-b-popover.hover.focus.html="popoverText">
            <a href="#">Ver aquí.</a>
          </span>
        </b-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="riesgos > 0">
        <b-alert show variant="danger">
          <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'times-circle', }" :style="{color: '#dc3545'}"/>
          <span v-if="profData.BloqueadoSinValidacion"> Se ha deshabilitado la opcion "MIS CFDIS" {{ profData.EsRepse ? 'y "MIS ORDENES"' : '' }} {{ !es_proveedor ? 'al proveedor' : '' }} porque:</span>
          Existen documentos con riesgos en su Perfil. Verifíquelos para poder reemplazarlos.</b-alert>
      </div>
      <div class="col-12" v-else-if="vencidos > 0">
        <b-alert show variant="danger">
          <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'times-circle', }" :style="{color: '#dc3545'}"/>
          <span v-if="profData.BloqueadoSinValidacion"> Se ha deshabilitado la opcion "MIS CFDIS" {{ profData.EsRepse ? 'y "MIS ORDENES"' : '' }} {{ !es_proveedor ? 'al proveedor' : '' }} porque:</span>
          Existen documentos vencidos en su Perfil. Reemplácelos para poder validarlos.</b-alert>
      </div>
      <div class="col-12" v-else-if="pendientes > 0">
        <b-alert show variant="dark">
          <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle', }" :style="{color: '#6c757d'}"/>
          <span v-if="profData.BloqueadoSinValidacion"> Se ha deshabilitado la opcion "MIS CFDIS" {{ profData.EsRepse ? 'y "MIS ORDENES"' : '' }} {{ !es_proveedor ? 'al proveedor' : '' }} porque:</span>
          Existen documentos en su Perfil pendientes de validar. En cuanto queden validados se le notificará por e-mail.</b-alert>
      </div>
      <div class="col-12" v-else-if="sincarga > 0">
        <b-alert show variant="light">
          <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle', }" :style="{color: '#6c757d'}"/>
          <span v-if="profData.BloqueadoSinValidacion"> Se ha deshabilitado la opcion "MIS CFDIS" {{ profData.EsRepse ? 'y "MIS ORDENES"' : '' }} {{ !es_proveedor ? 'al proveedor' : '' }} porque:</span>
          Existen documentos en su Perfil pendientes de cargar.</b-alert>
      </div>
      <div class="col-12" v-else-if="porvencer > 0">
        <b-alert show variant="warning">
          <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'times-circle', }" :style="{color: '#ff9800'}"/>
          Existen documentos por vencer en su Perfil. Si lo desea puede actualizarlos anticipadamente.</b-alert>
      </div>
      <div class="col-12" v-else-if="validos === filesData.length && filesData.length > 0">
        <b-alert show variant="success">
          <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'check-circle', }" :style="{color: '#168736'}"/>
          Todos los documentos de su Perfil han sido validados correctamente.</b-alert>
      </div>
    </div>
    <!-- <transition name="slide-fade" appear>
      <div class="row">
          <div class="col-12 col-md-6">
            <img class="img-thumb img-responsive" :src="logoUrl" alt="Empresa">
          </div>
        <div class="col-12 col-md-6">
          <dias-carga :datos="profData" :provId="ProvId" @submit="getMyData(true)"/>
        </div>
      </div>
    </transition>
    <br> -->
    <div class="row">
      <transition name="slide-fade" appear>
        <div class="col-12 col-md-6">
          <h5>Datos de la Empresa</h5>
          <table class="table table-info table-light">
            <tbody>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'building', }" class="icon btn-icon"/> Razón Social
                </td>
                <td>{{ profData.RazonSocial }}</td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'id-card', }" class="icon btn-icon"/> RFC
                </td>
                <td>{{ profData.Rfc }}</td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'id-card', }" class="icon btn-icon"/> Reg. Patronal
                </td>
                <td>{{ profData.RegPatronal }}</td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'user-tie', }" class="icon btn-icon"/> Representante Legal
                </td>
                <td>{{ profData.RepLegal }}</td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'map-marker-alt', }" class="icon btn-icon"/> Dirección Fiscal
                </td>
                <td>{{ profData.DomicilioFiscal }}</td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'file-contract', }" class="icon btn-icon"/> Proveedor R.E.P.S.E.
                </td>
                <td>
                  <span>
                    <b-badge class="font-size-12" variant="success" v-if="profData.EsRepse === 1">SI</b-badge>
                    <b-badge class="font-size-12" variant="secondary" v-else>NO</b-badge>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </transition>
      <transition name="slide-fade" appear>
        <div class="col-12 col-md-6">
          <h5>Datos de Contacto</h5>
          <table class="table table-info table-light">
            <tbody>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'user', }" class="icon btn-icon"/> Nombre de Contacto
                </td>
                <td>{{ profData.NombreContacto }}</td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'envelope', }" class="icon btn-icon"/> Correo de contacto
                </td>
                <td>{{ profData.Email }}</td>
              </tr>
              <tr>
                <td class="table-dark">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'mobile-alt', }" class="icon btn-icon"/> Telefono de contacto
                </td>
                <td>{{ profData.Telefono }}</td>
              </tr>
            </tbody>
          </table>
            <dias-carga v-if="(!es_proveedor && profData.EsRepse) || (es_proveedor && es_repse)" :datos="profData" :provId="ProvId" @submit="getMyData(true)"/>
        </div>
      </transition>
      <transition name="slide-fade" appear>
        <div class="col-12 mt-2" v-if="filesData.length > 0">
          <div class="table-responsive site-table-responsive">
            <table class="table site-table">
              <thead>
                <tr>
                  <th scope="col">ESTATUS</th>
                  <th scope="col">DESCRIPCION</th>
                  <th scope="col" class="text-center">VIGENCIA</th>
                  <th scope="col">ARCHIVO</th>
                  <th scope="col" v-if="$puede_cargar()">CARGAR</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="file in filesData"
                  :key="file.ArchivoId"
                  :class="file.ArchivoProvId && (file.EstatusClass === 'e-default') ? 'e-default-loaded' : file.EstatusClass"
                  @contextmenu.prevent="$eliminar(['Perfil']) && file.ArchivoProvId ? $refs.contextmenu.$refs.menu.open($event, file) : undefined">
                  <td class="round-left td-icon">
                    <span :class="file.ArchivoProvId ? 'pointer' : ''"
                      v-b-popover.hover="file.Estatus"
                      @click="file.ArchivoProvId ? openEstatusModal(file.ArchivoProvId) : undefined">
                      <font-awesome-icon class="icon" :icon="{ prefix: 'fa', iconName: `${file.IconEstatus}`, }" :style="{color: `${file.IconColor}`}"/>
                    </span>
                  </td>
                  <td class="spacer td-desc" :class="file.ArchivoProvId ? 'pointer' : ''" @click="file.ArchivoProvId ? openEstatusModal(file.ArchivoProvId): undefined">
                    {{ file.Descripcion }}
                    <small class="d-block" v-if="file.SubDescripcion">{{ file.SubDescripcion }}</small>
                  </td>
                  <td class="spacer text-center">
                    <span>{{ file.Vencimiento }}</span><br>
                    <span v-if="file.EstatusVencimiento !== ''"
                      v-b-popover.hover.html="`<strong>Cargado el:</strong> ${file.Fecha}<br><strong>Autor:</strong> ${file.Autor}`">
                      <b-badge :variant="file.EstatusVencimiento === 'e-invalid' ? 'danger' : (file.EstatusVencimiento === 'e-warning' ? 'warning' : '')">
                        {{ file.EstatusVencimiento === 'e-invalid' ? 'VENCIDO' : (file.EstatusVencimiento === 'e-warning' ? 'POR VENCER' : '') }}
                      </b-badge>
                    </span>
                  </td>
                  <td class="spacer td-icon">
                    <button type="button"
                      v-b-popover.hover.html="file.ArchivoProvId ? `<strong>Nombre:</strong> ${file.NomArchivo}<br><strong>Fecha:</strong> ${file.Fecha}<br><strong>Autor:</strong> ${file.Autor}` : 'Sin archivo'" title="Abrir archivo"
                      :class="file.ArchivoProvId ? 'btn btn-primary' : 'btn btn-link'"
                      :disabled="!file.ArchivoProvId || loading"
                      @click="$descargar(['Perfil']) && file.ArchivoProvId ? downloadFile(file.ArchivoProvId) : undefined">
                      <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'file-pdf', }" class="icon btn-icon"
                        v-if="loadingDown !== file.ArchivoProvId"/>
                      <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'spinner', }" class="icon btn-icon" spin
                        v-if="loadingDown == file.ArchivoProvId"/>
                    </button>
                  </td>
                  <td class="round-right td-icon" v-if="$puede_cargar()">
                    <label :for="!loading ? file.ArchivoId : ''" :class="!loading ? 'btn btn-link btn-border no-margin bg-white-no-hover' : 'btn btn-border disabled'"
                      v-b-popover.hover.html="'<strong>Tamaño máximo:</strong> 50MB<br><strong>Formato:</strong> PDF'" :title="file.ArchivoProvId ? 'Reemplazar archivo' : 'Subir archivo'"
                      :disabled="loading"
                      v-if="$cargar(['Perfil']) && (!file.ArchivoProvId || file.EstatusVencimiento === 'e-invalid' || file.EstatusClass === 'e-invalid' || file.EstatusVencimiento === 'e-warning' || file.EstatusClass === 'e-warning') && esActivo">
                      <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'upload', }" class="icon btn-icon"
                        v-if="loadingUp !== file.ArchivoId"/>
                      <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'spinner', }" class="icon btn-icon" spin
                        v-if="loadingUp == file.ArchivoId"/>
                    </label>
                    <input :id="file.ArchivoId" class="input-file" type="file" accept="application/pdf"
                      @change="$cargar(['Perfil']) && !loading ? onChangeFile(file.ArchivoId) : undefined"
                      :ref="'file' + file.ArchivoId"
                      v-if="$cargar(['Perfil']) && (!file.ArchivoProvId || file.EstatusVencimiento === 'e-invalid' || file.EstatusClass === 'e-invalid' || file.EstatusVencimiento === 'e-warning' || file.EstatusClass === 'e-warning') && esActivo">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </transition>
    </div>
    <context-menu ref="contextmenu" :enable="$eliminar(['Perfil'])" @clickContext="clickContext($event)"/>
    <b-modal
      id="bv-modal-perfil" size="lg" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'"
      v-if="$editar(['Perfil'])">
      <template #modal-title>
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'edit', }" class="icon btn-icon" /> EDITAR MIS DATOS
      </template>
      <form class="form-site inner" @submit.prevent="editMyData">
        <!-- <div class="wizard-header">
          <img class="img-thumb img-responsive" :src="logoModUrl" alt="Empresa">
          <div class="col-12">
            <label for="files" class="btn btn-link btn-border mt-1">Reemplazar imagen ...</label>
            <input id="files" class="input-file" type="file" accept="image/x-png,image/jpeg" @change="onChangeLogo" ref="fileLogo">
          </div>
        </div> -->
        <h6>Datos de la Empresa</h6>
        <div class="form-row">
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('razonSocial')}">
            <fieldset>
              <legend>Razón Social</legend>
              <input type="text" class="form-control" placeholder="Razón Social" v-model="razonSocial">
              <span class="icon" :title="validation.firstError('razonSocial')" v-if="validation.hasError('razonSocial')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
        </div>
        <div class="form-row" v-if="!es_proveedor">
          <div class="form-holder" :class="{error: validation.hasError('rfc')}">
            <fieldset>
              <legend>RFC</legend>
              <input type="text" class="form-control" placeholder="RFC" v-model="rfc">
              <span class="icon" :title="validation.firstError('rfc')" v-if="validation.hasError('rfc')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder" :class="{error: validation.hasError('registro_patronal')}">
            <fieldset>
              <legend>Reg. Patronal</legend>
              <input type="text" class="form-control" placeholder="Registro Patronal" v-model="registro_patronal">
              <span class="icon" :title="validation.firstError('registro_patronal')" v-if="validation.hasError('registro_patronal')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
        </div>
        <div class="form-row">
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('repLegal')}">
            <fieldset>
              <legend>Representante Legal</legend>
              <input type="text" class="form-control" placeholder="Representante Legal" v-model="repLegal">
              <span class="icon" :title="validation.firstError('repLegal')" v-if="validation.hasError('repLegal')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
        </div>
        <div class="form-row">
          <div class="form-holder form-holder-2" :class="{error: validation.hasError('dirFiscal')}">
            <fieldset>
              <legend>Dirección Fiscal</legend>
              <input type="text" class="form-control" placeholder="Dirección Fiscal" v-model="dirFiscal">
              <span class="icon" :title="validation.firstError('dirFiscal')" v-if="validation.hasError('dirFiscal')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
        </div>
        <h6>Datos de Contacto</h6>
        <div class="form-row">
          <div class="form-holder" :class="{error: validation.hasError('nomContacto')}">
            <fieldset>
              <legend>Nombre</legend>
              <input type="text" class="form-control" placeholder="Nombre del contacto" v-model="nomContacto">
              <span class="icon" :title="validation.firstError('nomContacto')" v-if="validation.hasError('nomContacto')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
          <div class="form-holder" :class="{error: validation.hasError('telContacto')}">
            <fieldset>
              <legend>Teléfono</legend>
              <input type="text" class="form-control" placeholder="Teléfono del contacto" v-model="telContacto">
              <span class="icon" :title="validation.firstError('telContacto')" v-if="validation.hasError('telContacto')">
                <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'exclamation-circle' }" class="icon"/>
              </span>
            </fieldset>
          </div>
        </div>
        <b-alert v-if="es_proveedor" show>** Si desea modificar su RFC o Registro patronal, favor de notificarlo via correo electrónico a proveedores@giaguila.com.mx</b-alert>
        <button type="submit" class="btn btn-block btn-action mt-3 mb-4" block>
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Actualizar datos
        </button>
      </form>
    </b-modal>
    <b-modal
      id="bv-modal-estatus" size="lg" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'">
      <template #modal-title>
        Estatus de {{ estatusFile.Descripcion }}
      </template>
      <form class="form-site inner" @submit.prevent="$validar(['Perfil']) ? updateEstatus() : ''">
        <div class="form-row" v-if="estatusFile.Metadata">
          <div class="form-holder form-holder-2">
            <fieldset class="max-height-30">
              <legend>Metadatos del Archivo</legend>
              <span>
                <b-form-textarea class="form-control" rows="1" max-rows="5" :value="estatusFile.Metadata" :plaintext="true"></b-form-textarea>
              </span>
            </fieldset>
          </div>
        </div>
        <div class="form-row" v-if="estatusFile.Validaciones">
            <div class="form-holder form-holder-2">
              <fieldset class="height-25">
                <legend class="legend-box legend-box-valid mb-2">Validaciones</legend>
                <span v-for="(validacion, index) in estatusFile.Validaciones.split('\n')" :key="index">
                  <span v-if="validacion">
                    <label>
                      <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'check-circle' }" :style="{color: 'rgb(44 207 109'}"/>
                      {{ validacion }}
                    </label><br>
                  </span>
                </span>
                <span v-if="!estatusFile.Validaciones.length">
                    <alert :variant="'default'" title="Sin Validaciones" :content="''" :icon="'check-circle'"></alert><br>
                </span>
              </fieldset>
            </div>
          </div>
        <div class="form-row">
          <div class="form-holder form-holder-2">
            <fieldset>
              <legend>Estatus</legend>
              <span v-if="!$validar(['Perfil']) || !estatusFile.ArchivoProvId">
                <label class="notation" :style="`border-color:${estatusFile.IconColor}`">
                  <font-awesome-icon :icon="{ prefix: 'fa', iconName: `${estatusFile.IconEstatus || 'exclamation-circle'}`, }" :style="{color: `${estatusFile.IconColor}`}"/>
                  {{ estatusFile.Estatus }}
                </label>
                <b-form-textarea class="form-control" rows="1" max-rows="5" :value="estatusFile.Observaciones" :plaintext="true" v-if="estatusFile.Observaciones"></b-form-textarea>
              </span>
              <span v-if="$validar(['Perfil']) && estatusFile.ArchivoProvId">
                <b-dropdown size="sm" class="btn-dropdown" variant="light">
                  <template #button-content>
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: `${estatusSelected.IconEstatus}`, }" :style="{color: `${estatusSelected.IconColor}`}"/>
                    {{ estatusSelected.Estatus }}
                  </template>
                  <b-dropdown-item-button
                    @click="changeEstatusArchivo(estatus)"
                    v-for="estatus in estatusData"
                    :key="estatus.EstatusId">
                    <font-awesome-icon :icon="{ prefix: 'fa', iconName: `${estatus.IconEstatus}`, }" :style="{color: `${estatus.IconColor}`}"/>
                    {{ estatus.Estatus }}</b-dropdown-item-button>
                </b-dropdown>
                <b-form-textarea class="form-control" rows="1" max-rows="5" placeholder="Observaciones del estatus para mostrar al proveedor" v-model="estatusObserv"></b-form-textarea>
              </span>
            </fieldset>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-action mb-2" block
          v-if="$validar(['Perfil']) && estatusFile.ArchivoProvId">
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Actualizar estatus
        </button>
      </form>
    </b-modal>

    <b-modal
        id="bv-modal-viewfile" size="xl" centered hide-footer content-class="site-modal modal-viewer"
        :header-text-variant="'light'">
        <template #modal-title>Manual de uso del Portal de Proveedores</template>
        <b-card class="min-padd" no-body>
              <div v-if="manualFile && !loadingView">
                <iframe
                  :src="manualFile"
                  @load="iFrameLoaded"
                  frameBorder="0"
                  scrolling="auto"
                  height="100%"
                  width="100%"
                ></iframe>
              </div>
              <div v-else class="empty-image center-content">
                <span v-if="!loadingView">
                  <img src="@/assets/empty.svg" alt="Empty">
                  <p class="empty-description">Sin archivo cargado</p>
                </span>
                <span v-else><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/></span>
              </div>
        </b-card>
        <b-button class="mt-3" variant="success" block @click="closeManual">"Confirmo que he leido el Manual"</b-button>
      </b-modal>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import DiasCarga from '@/components/DiasCarga.vue';
import ContextMenu from '@/components/ContextMenu.vue';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;
const logUrl = require('@/assets/logo-default.png');

export default {
  components: { DiasCarga, ContextMenu },
  props: [
    'ProvId',
    'es_proveedor',
    'es_interno',
    'es_repse',
  ],
  data() {
    return {
      loading: false,
      loadingDown: false,
      loadingUp: false,
      loadingView: false,
      manualFile: '',
      estatusData: {},
      estatusFile: '',
      estatusSelected: '',
      estatusObserv: '',
      listaNegra: {},
      esActivo: true,
      profData: {},
      filesData: [],
      logoUrl: logUrl,
      logoModUrl: logUrl,
      logoFile: null,
      file: null,
      razonSocial: '',
      rfc: '',
      repLegal: '',
      dirFiscal: '',
      nomContacto: '',
      telContacto: '',
      registro_patronal: '',
      popoverText: '',
    };
  },
  validators: {
    razonSocial(value) { return Validator.value(value).required('Campo requerido').lengthBetween(5, 250, 'Formato de 5 a 250 caracteres'); },
    rfc(value) { return Validator.value(value).required('Campo requerido').lengthBetween(12, 13, 'Formato de 12 o 13 caracteres'); },
    repLegal(value) { return Validator.value(value).required('Campo requerido').lengthBetween(5, 150, 'Formato de 5 a 150 caracteres'); },
    dirFiscal(value) { return Validator.value(value).lengthBetween(5, 150, 'Formato de 5 a 300 caracteres'); },
    nomContacto(value) { return Validator.value(value).required('Campo requerido').lengthBetween(5, 150, 'Formato de 5 a 150 caracteres'); },
    // telContacto(value) { return Validator.value(value).digit().length(10, 'Formato de 10 valores numéricos'); },
    telContacto: {
      cache: true,
      debounce: 200,
      validator(value) {
        // eslint-disable-next-line consistent-return
        return Validator.value(value).lengthBetween(0, 10, 'Formato de 10 valores numéricos').digit('Se requiere un número').custom(() => {
          if (!Validator.isEmpty(value)) {
            return this.$delay(1000)
              // eslint-disable-next-line consistent-return
              .then(() => {
                if (value.length !== 10) {
                  return 'Formato de 10 valores numéricos';
                }
              });
          }
        });
      },
    },
    registro_patronal: {
      cache: true,
      debounce: 200,
      validator(value) {
        // eslint-disable-next-line consistent-return
        return Validator.value(value).lengthBetween(0, 12, 'Solo se permiten 11 o 12 caracteres').custom(() => {
          if (!Validator.isEmpty(value)) {
            return this.$delay(1000)
              // eslint-disable-next-line consistent-return
              .then(() => {
                if (value.length < 11 || value.length > 12) {
                  return 'Solo se permiten 11 o 12 caracteres';
                }
              });
          }
        });
      },
    },
    // registro_patronal(value) { return Validator.value(value).required('Campo requerido').lengthBetween(11, 12, 'Formato de 11 o 12 caracteres'); },
  },
  mounted() {
    this.getMyData(true);
    this.getEstatus();
    this.getListaNegra();
  },
  methods: {
    viewManual() {
      if (this.profData.ManualLeido === 0 && this.es_proveedor) {
        this.loadingView = true;
        this.manualFile = '';
        apiClient.get('/perfil/manual', {
          responseType: 'blob',
        })
          .then((res) => {
            this.manualFile = URL.createObjectURL(res.data);
            URL.revokeObjectURL(res.data);
            this.loadingView = false;
          })
          .catch(() => {
            this.loadingView = false;
          });

        this.$bvModal.show('bv-modal-viewfile');
      }
    },
    iFrameLoaded() {
      URL.revokeObjectURL(this.manualFile);
    },
    getListaNegra() {
      apiClient.get('/perfil/lista-negra', {
        params: {
          ProvId: this.ProvId,
        },
      })
        .then((res) => {
          this.listaNegra = res.data;
        })
        .catch(() => {});
    },
    getEstatus() {
      apiClient.get('/estatus')
        .then((res) => {
          this.estatusData = res.data;
        })
        .catch(() => {});
    },
    getEstatusArchivo(id) {
      apiClient.get(`/perfil/${id}/estatus-archivo`, {
        params: {
          ProvId: this.ProvId,
        },
      })
        .then((res) => {
          this.estatusFile = res.data;
          this.estatusObserv = this.estatusFile.Observaciones;
          this.estatusSelected = this.estatusFile;
          this.$bvModal.show('bv-modal-estatus');
        })
        .catch(() => {});
    },
    openEstatusModal(fileId) {
      this.getEstatusArchivo(fileId);
    },
    changeEstatusArchivo(estatusSelected) {
      this.estatusSelected = estatusSelected;
    },
    updateEstatus() {
      this.loading = true;
      const { ArchivoProvId } = this.estatusFile;
      const { EstatusId } = this.estatusSelected;
      apiClient.put('/perfil/estatus', {
        ProvId: this.ProvId,
        archivoProvId: ArchivoProvId,
        idEstatus: EstatusId,
        observaciones: this.estatusObserv,
      })
        .then(() => {
          this.getFilesData();
          this.$bvModal.hide('bv-modal-estatus');
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    gotoOrdenes() {
      this.$router.push({ name: '/ordenes', params: { ProvId: `${this.ProvId}`, PrevRoute: '/perfil', NomProv: `${this.profData.RazonSocial}` } }).catch(() => {});
    },
    gotoFacturas() {
      this.$router.push({ name: '/facturas', params: { ProvId: `${this.ProvId}`, PrevRoute: '/perfil', NomProv: `${this.profData.RazonSocial}` } }).catch(() => {});
    },
    gotoDashboard() {
      this.$router.push({ name: '/dashboard' }).catch(() => {});
    },
    openEditProfile() {
      this.logoFile = null;
      this.logoModUrl = this.logoUrl;
      this.razonSocial = this.profData.RazonSocial;
      this.rfc = this.profData.Rfc;
      this.repLegal = this.profData.RepLegal;
      this.dirFiscal = this.profData.DomicilioFiscal;
      this.nomContacto = this.profData.NombreContacto;
      this.telContacto = this.profData.Telefono;
      this.registro_patronal = this.profData.RegPatronal;
      this.$bvModal.show('bv-modal-perfil');
    },
    editMyData() {
      this.$validate().then((success) => {
        if (success) {
          this.loading = true;
          // Formulario para envio de archivo con data
          const formData = new FormData();
          // formData.append('FileLogo', this.logoFile);
          formData.append('ProvId', this.ProvId);
          formData.append('RazonSocial', this.razonSocial);
          formData.append('Rfc', this.rfc);
          formData.append('RepLegal', this.repLegal);
          formData.append('DomicilioFiscal', this.dirFiscal);
          formData.append('NombreContacto', this.nomContacto);
          formData.append('Telefono', this.telContacto);
          formData.append('RegPatronal', this.registro_patronal);

          apiClient.put('/perfil', formData, {
            headers: {
              'Content-Type': 'multipart/form-data;',
            },
          })
            .then(() => {
              this.logoFile = null;
              this.getMyData(true);
              this.$bvModal.hide('bv-modal-perfil');
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    // Bloquear o desbloquear perfil
    bloquearPerfil() {
      this.loading = true;
      apiClient.post('/perfil/bloquear', {
        ProvId: this.ProvId,
      })
        .then(() => {
          this.getMyData(false);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // Bloquear o desbloquear validacion de documentos
    bloquearValidacion() {
      this.loading = true;
      apiClient.post('/perfil/bloquear-validacion', {
        ProvId: this.ProvId,
      })
        .then(() => {
          this.getMyData(false);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChangeLogo() {
      // Obtenemos el archivo del input
      // eslint-disable-next-line prefer-destructuring
      this.logoFile = this.$refs.fileLogo.files[0];
      // Reseteamos el input
      this.$refs.fileLogo.value = '';
      // Actualizamos la imagen en el 'img' del modal
      this.logoModUrl = URL.createObjectURL(this.logoFile);
      URL.revokeObjectURL(this.logoFile);
    },
    getMyData(withFiles) {
      apiClient.get('/perfil', {
        params: {
          ProvId: this.ProvId,
        },
      })
        .then((res) => {
          // eslint-disable-next-line prefer-destructuring
          this.profData = res.data;
          this.esActivo = res.data.EsActivo;
          this.obtenerFactConRepsPend();
          this.getFileLogo();
          this.viewManual();
          if (withFiles) { this.getFilesData(); }
        })
        .catch(() => {});
    },
    getFileLogo() {
      apiClient.get('/perfil/logo', {
        responseType: 'blob',
        params: {
          ProvId: this.ProvId,
        },
      })
        .then((res) => {
          this.logoUrl = URL.createObjectURL(res.data);
          URL.revokeObjectURL(res.data);
        })
        .catch(() => {});
    },
    getFilesData() {
      apiClient.get('/perfil/archivos', {
        params: {
          ProvId: this.ProvId,
        },
      })
        .then((res) => {
          this.filesData = res.data;
        })
        .catch(() => {});
    },
    onChangeFile(ArchivoId) {
      // Obtenemos el archivo del input
      // eslint-disable-next-line prefer-destructuring
      this.file = this.$refs[`file${ArchivoId}`][0].files[0];
      // Reseteamos el input
      this.$refs[`file${ArchivoId}`][0].value = '';
      // Cargamos el archivo al servidor
      this.uploadFile(ArchivoId);
    },
    uploadFile(ArchivoId) {
      this.loading = true;
      this.loadingUp = ArchivoId;
      // Formulario para envio de archivo con data
      const formData = new FormData();
      formData.append('File', this.file);
      formData.append('ArchivoId', ArchivoId);
      formData.append('ProvId', this.ProvId);
      apiClient.put('/perfil/archivos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      })
        .then(() => {
          this.file = null;
          this.getFilesData();
          this.loading = false;
          this.loadingUp = false;
        })
        .catch((err) => {
          this.file = null;
          this.loading = false;
          this.loadingUp = false;
          this.$bvModal.msgBoxOk(err.response.data.ms || '', {
            title: err.response.data.title || 'Atención',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'OK',
            cancelTitle: 'Cancelar',
            headerClass: 'text-light',
            footerClass: 'bg-light p-2',
            hideHeaderClose: true,
            centered: true,
          });
        });
    },
    downloadFile(ArchivoProvId) {
      this.loading = true;
      this.loadingDown = ArchivoProvId;
      apiClient.get(`/perfil/${ArchivoProvId}/archivo`, {
        responseType: 'blob',
        params: {
          ProvId: this.ProvId,
        },
      })
        .then((res) => {
          const fileUrl = URL.createObjectURL(res.data);
          URL.revokeObjectURL(res.data);
          this.loading = false;
          this.loadingDown = false;
          window.open(fileUrl, '_blank');
          // URL.revokeObjectURL(fileUrl);
        })
        .catch(() => {
          this.loading = false;
          this.loadingDown = false;
        });
    },
    deleteFile(ArchivoProvId) {
      this.loading = true;
      this.loadingDown = ArchivoProvId;
      apiClient.delete('/perfil/archivo', {
        data: {
          ProvId: this.ProvId,
          ArchivoProvId,
        },
      })
        .then(() => {
          this.loading = false;
          this.loadingDown = false;
          this.getFilesData();
        })
        .catch(() => {
          this.loading = false;
          this.loadingDown = false;
        });
    },
    clickContext(e) {
      if (e.action === 'delete' && this.$eliminar(['Perfil'])) {
        this.$bvModal.msgBoxConfirm('Si continua, se eliminará el archivo cargado en este registro.', {
          title: 'Eliminar Archivo',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Si, Eliminar',
          cancelTitle: 'Cancelar',
          headerClass: 'text-light',
          footerClass: 'bg-light p-2',
          hideHeaderClose: true,
          centered: true,
        })
          .then((confirm) => {
            if (confirm) this.deleteFile(e.data.ArchivoProvId);
          })
          .catch(() => {});
      }
    },
    openManual() {
      apiClient.get('/perfil/manual', {
        responseType: 'blob',
      })
        .then((res) => {
          const linkPdf = document.createElement('a');
          linkPdf.href = URL.createObjectURL(res.data);
          linkPdf.download = 'Manual de Uso del Proveedor.pdf';
          linkPdf.click();
          URL.revokeObjectURL(res.data);
          URL.revokeObjectURL(linkPdf.href);
        })
        .catch(() => {
        });
    },
    closeManual() {
      this.$bvModal.hide('bv-modal-viewfile');
      apiClient.post('/perfil/manual-leido')
        .then(() => {})
        .catch(() => {});
    },
    obtenerFactConRepsPend() {
      let factSinRep = this.profData.FactSinRep;
      let importeSinRep = this.profData.ImporteSinRep;
      let fechaSinRep = this.profData.FechaSinRep;
      if (factSinRep && importeSinRep && fechaSinRep) {
        factSinRep = factSinRep.split(',');
        importeSinRep = importeSinRep.split(',');
        fechaSinRep = fechaSinRep.split(',');
        if (factSinRep.length > 0 && importeSinRep.length > 0 && fechaSinRep.length > 0) {
          this.popoverText = '';
          factSinRep.forEach((row, index) => {
            this.popoverText = `${this.popoverText}<strong>UUID: </strong>${factSinRep[index]}<br><strong>Importe: </strong>${this.$currencyFormat(importeSinRep[index])}<br><strong>Fecha: </strong>${fechaSinRep[index]}<hr>`;
          });

          this.popoverText = this.popoverText.slice(0, -4);
        }
      }
    },
  },
  computed: {
    riesgos() {
      const riesgos = this.filesData.filter((file) => file.EstatusClass === 'e-invalid').length;
      return riesgos;
    },
    vencidos() {
      const vencidos = this.filesData.filter((file) => file.EstatusVencimiento === 'e-invalid').length;
      return vencidos;
    },
    porvencer() {
      const porvencer = this.filesData.filter((file) => file.EstatusVencimiento === 'e-warning').length;
      return porvencer;
    },
    pendientes() {
      const pendientes = this.filesData.filter((file) => file.EstatusClass === 'e-default' && file.ArchivoProvId && file.EsOpcional === 0).length;
      return pendientes;
    },
    sincarga() {
      const sincarga = this.filesData.filter((file) => file.EstatusClass === 'e-default' && !file.ArchivoProvId && file.EsOpcional === 0).length;
      return sincarga;
    },
    validos() {
      const validos = this.filesData.filter((file) => file.EstatusClass === 'e-valid').length;
      return validos;
    },
  },
};
</script>
<style land="scss" scoped>
.b-popover {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;
}
</style>
